/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '▼';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '▲';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
:global(.smallSidebar) .feed-btn {
  -webkit-transform: translate3d(65px, -50%, 0);
  transform: translate3d(65px, -50%, 0);
}
.feed-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(68px, -50%, 0);
  transform: translate3d(68px, -50%, 0);
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: none;
}
.feed-btn svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .feed-btn {
    -webkit-transform: translate3d(65px, -50%, 0);
    transform: translate3d(65px, -50%, 0);
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 240px;
  opacity: 0;
  z-index: 25;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.2s ease;
  background-color: rgba(34, 38, 41, 0.8);
  overflow: hidden;
}
@media (max-width: 1367px) {
  .overlay {
    left: 80px;
  }
}
.overlayOpen {
  opacity: 1;
}
.overlayClose {
  opacity: 0;
  transition-delay: 0.1s;
}
.modal {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  inset: unset;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-right: 1px solid #41494f;
  border-radius: 0;
  background-color: #222629;
  padding: 30px;
  width: 100%;
  max-width: 650px;
  height: 100vh;
  overflow: auto;
}
.modal h3 {
  margin-bottom: 30px;
}
.modalOpen {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.modalClose {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
