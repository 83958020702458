/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '▼';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '▲';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.wrap {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .wrap {
    flex-direction: column-reverse;
    height: auto;
    min-height: 100vh;
    font-size: 16px;
  }
  .wrap h3 {
    font-size: 30px;
    line-height: 1.25em;
  }
}
.form {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  z-index: 2;
  transition: -webkit-transform 0.75s ease;
  transition: transform 0.75s ease;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease;
  background-color: #222629;
  padding: 30px;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .form {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    width: 100vw;
    height: auto;
    min-height: calc(100vh - 200px);
  }
}
.validSlug .form {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.form h3 {
  margin-bottom: 30px;
  width: 100%;
}
.form h3 + p {
  margin-top: -15px;
  margin-bottom: 30px;
}
.form form {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;
}
@media (max-width: 1000px) {
  .form form {
    align-items: flex-start;
    margin-top: -67.5px;
  }
}
.form footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 12px;
}
.form footer .branding {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
  font-weight: lighter;
  font-size: 16px;
}
.form footer .logo svg {
  width: 24px;
  /* Scale down the SVG width */
  height: 24px;
  /* Scale down the SVG height */
}
.form footer span {
  display: inline-block;
  padding: 0 10px;
}
.form footer a {
  color: #8a96a0;
  text-decoration: none;
}
.form footer a:hover {
  text-decoration: underline;
}
.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.form-wrap img {
  object-fit: contain;
}
.form-wrap > *:not(:last-child, svg) {
  margin-bottom: 1.875rem;
}
.form-wrap > div {
  width: 100%;
}
.form-wrap > svg {
  margin-bottom: 60px;
}
.form-wrap label {
  width: 100%;
}
.form-wrap button {
  margin: 0 0 30px;
  width: 100%;
}
.notice {
  margin: 0 0 30px;
  width: 100%;
}
.img {
  position: relative;
  z-index: 1;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .img {
    width: 100vw;
    height: 200px;
  }
}
.img img {
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease !important;
  transition: transform 0.75s ease !important;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease !important;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.validSlug .img img {
  -webkit-transform: translateX(-25vw);
  transform: translateX(-25vw);
}
@media (max-width: 1000px) {
  .img img {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
.fade {
  opacity: 0.3 !important;
}
s .input {
  margin-bottom: 15px;
  border: 1px solid #41494f;
  border-radius: 4px;
  padding: 0 0 0 14px;
  width: 100%;
}
.why {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.why button {
  opacity: 0.25;
  margin: 0;
  width: auto;
}
.why button span {
  text-decoration: none;
}
