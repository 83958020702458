/* Fonts */
/* SFUI */
@font-face {
  font-style: normal;
  font-weight: 500;
  src: url('./_fonts/hinted-SFUIText-Medium.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-Medium.woff') format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: bold;
  src: url('./_fonts/hinted-SFUIText-BoldItalic.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-BoldItalic.woff') format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: normal;
  src: url('./_fonts/hinted-SFUIText-Regular.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-Regular.woff') format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: normal;
  src: url('./_fonts/hinted-SFUIText-RegularItalic.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-RegularItalic.woff') format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: italic;
  font-weight: 500;
  src: url('./_fonts/hinted-SFUIText-MediumItalic.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-MediumItalic.woff') format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: bold;
  src: url('./_fonts/hinted-SFUIText-Bold.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-Bold.woff') format('woff');
  font-family: 'SF UI Text';
}
@font-face {
  font-style: normal;
  font-weight: 900;
  src: url('./_fonts/hinted-SFUIText-Heavy.woff2') format('woff2'), url('./_fonts/hinted-SFUIText-Heavy.woff') format('woff');
  font-family: 'SF UI Text';
}
/* SFMono */
@font-face {
  src: url('./_fonts/SFMono/SFMonoRegular.woff') format('woff');
  font-family: 'SF Mono';
}
/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '▼';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '▲';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  vertical-align: baseline;
  margin: 0;
  background: transparent;
  padding: 0;
  font-size: 100%;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  margin: 1em 0;
  border: 0;
  border-top: 1px solid #cccccc;
  padding: 0;
  height: 1px;
}
input,
select {
  vertical-align: middle;
}
* {
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-rendering: optimizeLegibility !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
html {
  height: 100%;
}
body {
  position: relative;
  background-color: #222629;
  width: 100vw;
  max-width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: #8a96a0;
  font-weight: 100;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
}
@media print {
  body {
    background-color: transparent;
    color: #1a1d1f;
  }
}
body.ReactModal__Body--open {
  overflow: hidden;
}
::selection {
  background: rgba(138, 150, 160, 0.5);
}
::-moz-selection {
  background: rgba(138, 150, 160, 0.5);
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8a96a0;
}
h1,
.h1,
h2,
h3 {
  color: #fff;
  font-weight: bold;
}
@media print {
  h1,
  .h1,
  h2,
  h3 {
    color: #1a1d1f;
  }
}
h1,
.h1 {
  font-size: 40px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-weight: bold;
  font-size: 18px;
}
form > *:not(:last-child) {
  margin-bottom: 1rem;
}
.w {
  color: #fff;
}
h5,
.h5 {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}
h6 {
  margin: 30px 0 10px;
  font-weight: 100;
  font-size: 11px;
  text-transform: uppercase;
}
h2 + p,
h3 + p,
h4 + p,
h5 + p {
  margin-top: 15px;
}
ul {
  list-style: none;
}
p {
  line-height: 1.7em;
}
p:not(:last-child) {
  margin-bottom: 15px;
}
a {
  transition: color 0.15s ease;
  color: #fff;
}
a:hover {
  color: #8a96a0;
}
strong {
  color: inherit;
  font-weight: bold;
}
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
code {
  display: block;
  margin: 5px 0;
  border: 2px dashed #f8c81c;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 7px;
  font-family: 'Fira Code', monospace;
}
code.nice {
  border: none;
}
hr {
  margin: 30px 0;
  border: none;
  border-bottom: 1px solid #41494f;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
.hidden {
  position: absolute;
  top: -1px;
  left: -1px;
  border: 0;
  width: 0;
  height: 0;
}
@layer {
  /* unstyled button */
  button.unstyled {
    border: none;
    background-color: transparent;
    color: #8a96a0;
    text-align: left;
  }
  button.unstyled:not(:disabled) {
    cursor: pointer;
  }
}
.nospin input[type='number']::-webkit-outer-spin-button,
.nospin input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.nospin input[type='number'] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
input[type='number'].nospin::-webkit-outer-spin-button,
input[type='number'].nospin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'].nospin {
  -webkit-appearance: textfield;
  appearance: textfield;
}
.noscroll {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.ReactModal__Overlay {
  -webkit-perspective: 1600px;
  perspective: 1600px;
  opacity: 0;
  z-index: 25;
  transition: opacity 150ms ease-in-out;
}
.ReactModal__Overlay > div {
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  /* Had to disable this so dnd will play nice inside modals :(
		 * https://github.com/atlassian/react-beautiful-dnd#warning-position-fixed
		 */
  /*> div {
			transform: scale(1);
		}*/
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Overlay--before-close > div {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.recharts-text {
  fill: #8a96a0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.recharts-tooltip-cursor,
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #41494f;
}
.recharts-cartesian-grid line {
  stroke: #41494f;
}
.recharts-cartesian-axis > .recharts-text {
  fill: #8a96a0;
  text-anchor: middle;
}
.recharts-polar-angle-axis .recharts-text {
  fill: #8a96a0;
}
.rsel__control {
  transition: border-color 0.15s ease, opacity 0.15s ease !important;
  border-color: #41494f !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  min-height: 39px !important;
}
.rsel__control input {
  color: #8a96a0 !important;
}
.rsel__control:hover {
  border-color: #8a96a0 !important;
}
.rsel__control + div {
  position: relative;
  z-index: 2;
}
.rsel__control--is-disabled {
  opacity: 0.5;
}
.rsel__control--is-focused,
.rsel__control--is-focused:hover {
  box-shadow: none !important;
  border-color: #fff !important;
}
.rsel__placeholder {
  color: rgba(138, 150, 160, 0.3) !important;
}
.rsel__indicator:hover {
  color: #8a96a0 !important;
}
.rsel__indicator-separator {
  background-color: #41494f !important;
}
.rsel__single-value {
  padding: 1px 0;
  color: #fff !important;
}
.rsel__menu {
  position: absolute !important;
  z-index: 25;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #41494f !important;
  background-color: #1a1d1f !important;
}
.rsel__option {
  transition: background-color 0.15s ease;
}
.rsel__option:hover,
.rsel__option--is-focused {
  background-color: rgba(65, 73, 79, 0.25) !important;
}
.rsel__option--is-selected,
.rsel__option--is-selected:hover {
  background-color: #41494f !important;
  color: #fff !important;
}
.rsel__option--is-selected *,
.rsel__option--is-selected:hover * {
  color: #fff !important;
}
.rsel__multi-value__remove:hover {
  background-color: #ee1737 !important;
}
.rsel__multi-value__remove:hover path {
  fill: #fff;
}
.rsel__multi-value {
  padding: 4px;
  font-size: 16px;
}
.rsel__value-container--is-multi {
  padding: 2px !important;
}
.rsel__value-container--is-multi input {
  margin-left: 8px;
}
.rsel__value-container--is-multi .rsel__placeholder {
  margin-left: 8px !important;
}
.rbc-calendar {
  height: 100%;
}
.rbc-calendar .rbc-time-header {
  z-index: 3;
  background-color: #222629;
}
.rbc-calendar .rbc-time-header .rbc-time-header-gutter {
  border-bottom: 1px solid #41494f;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  z-index: 3;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .menu,
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .rbc-timeslot-create {
  display: none;
}
.rbc-calendar .rbc-allday-cell {
  display: none;
}
.rbc-calendar .rbc-current-time-indicator {
  background-color: #f8c81c;
  width: 700%;
}
.rbc-calendar .rbc-timeslot-group {
  min-height: 77px;
}
.rbc-calendar .rbc-timeslot-group .rbc-label {
  display: block;
  background-color: #222629;
  padding: 0 7.5px;
  font-size: 11px;
}
.rbc-calendar .rbc-timeslot-group:not(:first-child) .rbc-label {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
.rbc-calendar .rbc-time-header-content {
  border-left: 0;
}
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-header + .rbc-header {
  border-color: #41494f;
}
.rbc-calendar .rbc-header:first-child {
  border-left: 1px solid transparent;
}
.rbc-calendar .rbc-header + .rbc-header {
  border-image: linear-gradient(to top, #41494f, #222629) 1 1%;
}
.rbc-calendar .rbc-header {
  padding: 1rem;
  text-align: left;
}
.rbc-calendar .rbc-header button {
  letter-spacing: 0.46px;
  text-transform: uppercase;
}
.rbc-calendar .rbc-header button strong {
  display: block;
  margin-top: 0.2rem;
  color: white;
  font-size: 1.5rem;
  text-align: left;
}
.rbc-calendar .rbc-time-header.rbc-overflowing {
  border-right: 0;
}
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-events-container,
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-timeslot-group {
  border-left: 0;
}
.rbc-calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
.rbc-calendar .rbc-time-slot {
  background-color: #1a1d1f !important;
}
.rbc-calendar .rbc-label.rbc-time-header-gutter {
  background-color: #222629 !important;
}
.rbc-calendar .rbc-label.rbc-time-header-gutter {
  border-right: none;
  border-bottom: 1px #41494f solid;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  border: none;
}
.rbc-calendar .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border: none;
}
.rbc-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 0;
}
.rbc-calendar .rbc-time-view {
  border: none;
  height: 100%;
}
.rbc-calendar .rbc-time-view-resources {
  border: none;
  height: 100%;
}
.rbc-calendar .rbc-time-content {
  border-top: 0;
  overflow-x: hidden;
}
.rbc-calendar .rbc-timeslot-group {
  position: relative;
  border-bottom: 1px solid rgba(65, 73, 79, 0.3);
}
.rbc-calendar .rbc-time-content > * + * > * {
  border-left: 1px solid #41494f;
}
.rbc-calendar .rbc-today {
  background-color: rgba(248, 200, 28, 0.05);
}
.rbc-calendar .rbc-today.rbc-header * {
  color: #f8c81c;
}
.rbc-calendar .rbc-day-slot .rbc-event {
  z-index: 2;
  border: 0;
  border-left: 4px solid;
  overflow: visible;
}
.rbc-calendar .rbc-event-wrapper .rbc-event,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview {
  gap: 0.2rem;
  padding: 0.3rem 0.5rem;
  overflow: hidden;
}
.rbc-calendar .rbc-event-wrapper .rbc-event strong,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview strong {
  display: block;
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event *,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview * {
  font-size: 12px;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-label {
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg path {
  fill: currentColor;
}
.rbc-calendar.rbc-addons-dnd-is-dragging .rbc-event-wrapper.dragging .rbc-event {
  opacity: 0 !important;
}
.rbc-calendar .rbc-event-wrapper.workout .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.workout .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #f8c81c;
  background-color: rgba(248, 200, 28, 0.3);
  color: #f8c81c;
}
.rbc-calendar .rbc-event-wrapper.competition .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.competition .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #48c118;
  background-color: rgba(72, 193, 24, 0.3);
  color: #48c118;
}
.rbc-calendar .rbc-event-wrapper.training .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.training .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #2196f3;
  background-color: rgba(33, 150, 243, 0.3);
  color: #2196f3;
}
.rbc-calendar .rbc-event-wrapper.meal .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meal .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #ff7f2f;
  background-color: rgba(255, 127, 47, 0.3);
  color: #ff7f2f;
}
.rbc-calendar .rbc-event-wrapper.meeting .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #f48ab9;
  background-color: rgba(244, 138, 185, 0.3);
  color: #f48ab9;
}
.rbc-calendar .rbc-event-wrapper.meeting2 .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting2 .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #ab8af4;
  background-color: rgba(171, 138, 244, 0.3);
  color: #ab8af4;
}
.rbc-calendar .rbc-event-wrapper.travel .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.travel .rbc-event.rbc-addons-dnd-drag-preview {
  border-color: #8a96a0;
  background-color: rgba(138, 150, 160, 0.3);
  color: #8a96a0;
}
@media print {
  .rsel__indicator-separator,
  .rsel__dropdown-indicator {
    display: none !important;
  }
}
.rsel__dropdown-indicator {
  display: none !important;
}
