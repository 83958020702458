/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  border: 1px solid #41494f;
  border-radius: 5px;
  background-color: #1a1d1f;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  margin-bottom: 0.5rem;
  color: #ee1737;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  border: 1px solid #41494f;
  border-radius: 5px;
  padding: 30px 30px 15px 0;
}
.graphHeader,
.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  justify-content: center;
  align-items: center;
}
.bodyMapDot {
  position: absolute;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
  width: 20px;
  height: 20px;
}
.good,
.success {
  color: #26d87c;
}
.warning {
  color: #f8c81c;
}
.bad,
.danger {
  color: #ee1737;
}
.niceSwitch {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #1a1d1f;
  padding: 10px 12px;
}
.niceSwitch + .niceSwitch {
  margin-top: calc(-1rem + 8px);
}
.switch {
  position: relative;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.2rem;
}
.switch label {
  display: inline-block;
  transition: all 0.15s;
  cursor: pointer;
  border-radius: 16px;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 0.75rem;
}
.switch label:has(input:checked) {
  background-color: white;
  color: #1a1d1f;
}
.switch label:has(input:focus-visible) {
  outline: -webkit-focus-ring-color auto 1px;
}
.switch label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
th:has(.tableSortButton) {
  padding: 0 !important;
}
th:has(.tableSortButton) .tableSortButton {
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
th:has(.tableSortButton) .tableSortButton::after {
  cursor: pointer;
  margin-left: 0.2rem;
}
th:has(.tableSortButton) .tableSortButton.descending::after {
  content: '▼';
}
th:has(.tableSortButton) .tableSortButton.ascending::after {
  content: '▲';
}
table.tableSort th:has(button) {
  padding: 0 !important;
}
table.tableSort th:has(button) > button {
  cursor: pointer;
  padding: 1rem !important;
  width: 100%;
}
@media print {
  .printHidden {
    display: none !important;
  }
}
.pageHead {
  position: relative;
  background-color: #1a1d1f;
  padding: 2rem;
}
.pageHead::after {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, #1a1d1f, #41494f);
  height: 1px;
  content: '';
}
input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.noArrows[type='number'] {
  -moz-appearance: textfield;
}
div.light {
  border-radius: 50%;
  background-color: #41494f;
  width: 8px;
  height: 8px;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
body:global(.fixedHeight),
body:global(.fixedHeight #root),
body:global(.fixedHeight #root) main {
  height: 100%;
  overflow: hidden;
  /* safari x scroll */
}
body:global(.smallSidebar) .sidebar {
  -webkit-transform: translateX(calc(-100% +  80px));
  transform: translateX(calc(-100% +  80px));
}
body:global(.smallSidebar) .nav {
  -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
  transform: translateX(calc(100% -  80px - 0.5rem));
}
body:global(.smallSidebar) .nav a span {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
body:global(.smallSidebar) .version {
  display: none;
}
body:global(.smallSidebar) .logout {
  -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
  transform: translateX(calc(100% - 80px - 30px / 2));
}
body:global(.smallSidebar) .logout span {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
body:global(.smallSidebar) .logout svg {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
body:global(.smallSidebar) .body {
  margin-left: 80px;
}
.sidebar {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 20;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  background-color: #1a1d1f;
  width: 240px;
  height: 100vh;
  overflow: auto;
}
@media (max-width: 1367px) {
  .sidebar {
    -webkit-transform: translateX(calc(-100% +  80px));
    transform: translateX(calc(-100% +  80px));
  }
}
@media print {
  .sidebar {
    display: none;
    transition: none;
  }
}
.sidebar.super {
  border-right-color: #8a96a0;
  background-color: #41494f;
}
.logo {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: calc(100px + 30px*2);
}
.logo img {
  max-width: 80px;
  height: auto;
}
.nav {
  flex-grow: 1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 0 1rem 1rem;
}
@media (max-width: 1367px) {
  .nav {
    -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
    transform: translateX(calc(100% -  80px - 0.5rem));
  }
}
.nav a {
  display: flex;
  align-items: center;
  transition: color 0.15s ease, background-color 0.15s ease;
  margin: 0 0 10px;
  border-radius: 20px;
  padding: 1.1rem;
  width: 100%;
  height: 32px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}
.nav a svg {
  vertical-align: middle;
  margin-right: 15px;
  width: 20px;
}
.nav a svg line,
.nav a svg path,
.nav a svg polyline,
.nav a svg polygon,
.nav a svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
.nav a span {
  flex-grow: 1;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  color: inherit;
}
@media (max-width: 1367px) {
  .nav a span {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.nav a i {
  display: inline-block;
  border-radius: 50%;
  background-color: #f8c81c;
  width: 8px;
  height: 8px;
}
.nav a.active,
.nav a:hover {
  color: #fff;
}
.nav a.active svg line,
.nav a:hover svg line,
.nav a.active svg path,
.nav a:hover svg path,
.nav a.active svg polyline,
.nav a:hover svg polyline,
.nav a.active svg polygon,
.nav a:hover svg polygon,
.nav a.active svg rect,
.nav a:hover svg rect {
  stroke: #fff;
}
.nav a.active {
  background-color: #485056;
}
:global(.smallSidebar) .collapseMenuButton {
  align-self: flex-end;
  width: 50px;
}
:global(.smallSidebar) .collapseMenuButton svg {
  rotate: 180deg;
}
.collapseMenuButton {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 0.5rem;
  transition: color 0.15s, width 0.5s;
  cursor: pointer;
  margin: 1rem;
  border-radius: 16px;
  background-color: #222629;
  padding: 0.5rem 1rem;
  width: calc(100% - 2rem);
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 1367px) {
  .collapseMenuButton {
    display: none;
  }
}
.collapseMenuButton .text {
  overflow: hidden;
}
.collapseMenuButton svg {
  min-width: 18px;
}
.collapseMenuButton svg g {
  transition: stroke 0.15s;
}
.collapseMenuButton:hover {
  color: #a6afb7;
}
.collapseMenuButton:hover svg g {
  stroke: #a6afb7;
}
.version {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  padding: 15px 30px;
  color: rgba(138, 150, 160, 0.5);
  font-size: 11px;
}
.version button {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  margin: 0 0 0 10px;
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  color: red;
}
@media (max-width: 1367px) {
  .version {
    display: none;
  }
}
.logout {
  -webkit-appearance: none;
  appearance: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  cursor: pointer;
  border: none;
  border-top: 1px solid #41494f;
  background: none;
  padding: 30px;
  color: #8a96a0;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
}
@media (max-width: 1367px) {
  .logout {
    -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
    transform: translateX(calc(100% - 80px - 30px / 2));
  }
}
.super .logout {
  border-top-color: #485056;
}
.logout span {
  display: inline-block;
  transition: color 0.15s ease, -webkit-transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .logout span {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}
.logout svg {
  vertical-align: middle;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  margin-right: 15px;
  width: 20px;
}
.logout svg line,
.logout svg path,
.logout svg polyline,
.logout svg polygon,
.logout svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
@media (max-width: 1367px) {
  .logout svg {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
.logout.active span,
.logout:hover span {
  color: #fff;
}
.logout.active svg line,
.logout:hover svg line,
.logout.active svg path,
.logout:hover svg path,
.logout.active svg polyline,
.logout:hover svg polyline,
.logout.active svg polygon,
.logout:hover svg polygon,
.logout.active svg rect,
.logout:hover svg rect {
  stroke: #fff;
}
.body {
  display: flex;
  flex-direction: column;
  transition: margin-left 0.5s ease;
  margin-left: 240px;
  min-height: 100vh;
}
@media print {
  .body {
    transition: none;
    margin-left: 0 !important;
  }
}
@media (max-width: 1367px) {
  .body {
    margin-left: 80px;
  }
}
